import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();
    navBarShort = false;
    btnNew = false;
    urlRoute: string;

    home: boolean;
    avaliation: boolean;
    config: boolean;
    management: boolean;
    users: boolean;

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService) {

        super(router, translate);
    }

    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                this.urlRoute = this.getRoute();
            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    this.actionUserProfile();
                }
            }
        });
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#idea').collapse('hide');
        $('#clipboard').collapse('hide');
        $('#setting').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    getRoute() {
        const t = this.router.url.split('/');
        return '/' + t[1];
    }


    actionUserProfile() {
        switch (this.user.profile) {
            case 'admin':
                this.home = true;
                this.avaliation = true;
                this.config = true;
                this.management = true;
                this.users = true;
                break;
            case 'manager':
                this.home = true;
                this.avaliation = true;
                this.config = false;
                this.management = true;
                this.users = true;
                break;
            case 'leader':
                this.home = true;
                this.avaliation = true;
                this.config = false;
                this.management = false;
                this.users = false;
                break;
            case 'user':
                this.home = false;
                this.avaliation = true;
                this.config = false;
                this.management = false;
                this.users = false;
                break;
        }
        console.log(this.user.profile);
    }
}
